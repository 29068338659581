import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import konservatif from "../../assets/SVGIcon/konservatif.svg";
import moderat from "../../assets/SVGIcon/moderat.svg";
import agresif from "../../assets/SVGIcon/agresif.svg";
import styles from "./Result.module.css";
import { useNavigate } from "react-router-dom";
import { Konservatif, Moderat, Agresif } from "./Profiles";
import { Close } from "../../assets/SVGIcon";
import { useAuth, aesDecrypt, aesEncrypt, useAxios } from "@pru-cnp/ui-polisku";

import mobileLogo from "../../assets/Icon/pru-icon.png";

export const Result = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const deviceID = window.localStorage.getItem("deviceID");
  const assessmentResults = JSON.parse(
    window.localStorage.getItem("assessmentResults")
  );

  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const profileResults = {
    Konservatif: {
      result: Konservatif,
      icon: konservatif,
      bg: "#E5EAEF",
      desc: "Tingkat Risiko Rendah",
    },
    Moderat: {
      result: Moderat,
      icon: moderat,
      bg: "#d8d8d8",
      desc: "Tingkat Risiko Sedang",
    },
    Agresif: {
      result: Agresif,
      icon: agresif,
      bg: "#1B365D",
      desc: "Tingkat Risiko Tinggi",
    },
  };

  const handleNext = () => {
    useAxios({
      method: "post",
      url: "/risk-profile/get-recomended-fund",
      body: {
        risk: assessmentResults?.currentResult?.recomended_fund_level,
      },
    }).then((response) => {
      if (response?.status == 200) {
        if (response?.data?.resp_code == 0) {
          let isUnMatch = 0;
          if (assessmentResults?.currentResult?.recomended_fund_level !== "H") {
            user?.session?.client_data?.policies?.forEach((product) => {
              const productInRecom = response.data?.data.find((item) => {
                if (item[0])
                  return item[0].product_code === product.product_code;
              });
              if (productInRecom) {
                product.isSpesialCase = productInRecom[0]?.is_spesial_case;
              }
              if (product?.fund_list?.length === 0) return;
              product?.fund_list?.forEach((item) => {
                if (
                  product?.isSpesialCase &&
                  (item?.risk === "Rendah" || item?.risk === "Sedang")
                )
                  return;

                if (
                  assessmentResults?.currentResult?.recomended_fund_level ===
                    "L" &&
                  item?.risk ===
                    assessmentResults?.currentResult?.recomended_fund_level
                )
                  return;

                if (
                  assessmentResults?.currentResult?.recomended_fund_level ===
                    "M" &&
                  item?.risk !== "Tinggi"
                )
                  return;
                isUnMatch++;
              });
            });
          }
          const resultMatchings = {
            isUnMatch,
            fundLevel: assessmentResults?.currentResult?.recomended_fund_level,
            recommendedFunds: response.data?.data,
          };
          localStorage.setItem(
            "resultMatchings",
            JSON.stringify(resultMatchings)
          );
          window.localStorage.setItem("user", aesEncrypt(JSON.stringify(user)));
          navigate("/risk-profile/result-matching");
        } else {
          alert(response?.data?.resp_desc || "Terjadi kesalahan.");
        }
      } else {
        if (response?.status === 403 || response?.status === 401) {
          alert(response?.data?.resp_desc || "Terjadi kesalahan.");
          logout();
        }
      }
    });
  };
  return (
    <div className={styles.frame}>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "360px",
            marginTop: "3vh",
            [theme.breakpoints.up("md")]: {
              width: "85vw",
            },
          })}
        >
          <div
            onClick={() => {
              localStorage.removeItem("assessmentResults");
              localStorage.removeItem("resultMatchings");
              navigate(-1);
            }}
            style={{ height: "min-content" }}
            className={styles.kembali}
          >
            Cek ulang Profil Risiko
          </div>
          <Box
            sx={(theme) => ({
              backgroundColor: "#e5eaef",
              display: "hidden",
              [theme.breakpoints.up("md")]: {
                display: "flex",
                justifyContent: "flex-end",
                height: "30vh",
                width: "30vw",
              },
            })}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginTop: "24px",
            width: "360px",
            [theme.breakpoints.up("md")]: {
              width: "60vw",
              marginTop: "-25vh",
              marginBottom: "25vh",
              backgroundColor: "#ffffff",
              padding: "30px",
              borderRadius: "8px",
              boxShadow: "0px 26px 30px 0px rgba(0, 0, 0, 0.03)",
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              color: "#000",
              fontSize: "24px",
              fontFamily: "Helvetica",
              fontWeight: 700,
              marginTop: "-1px",
              lineHeight: "32px",
              width: "320px",
              [theme.breakpoints.up("md")]: {
                lineHeight: "40px",
                fontSize: "30px",
                width: "100%",
              },
            })}
          >
            Berikut adalah profil risiko terbaru Anda:
          </Typography>
          <div className={styles.div_2}>
            <div className={styles.group_wrapper}>
              <img
                className={styles.group}
                alt="Group"
                src={
                  profileResults[
                    assessmentResults?.currentResult?.assessment_result
                  ]?.icon
                }
              />
            </div>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "24px",
                width: "320px",
                [theme.breakpoints.up("md")]: {
                  width: "60vw",
                },
              })}
            >
              <Box
                sx={{
                  backgroundColor:
                    profileResults[
                      assessmentResults?.currentResult?.assessment_result
                    ]?.bg,
                  padding: "0px 30px 30px 30px",
                  marginTop: "10px",
                }}
                ki
                className={styles.div_3}
              >
                <Box
                  sx={{
                    color:
                      assessmentResults?.currentResult?.assessment_result ===
                      "Agresif"
                        ? "#fff"
                        : "#333",
                    alignSelf: "flex-start",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography className={styles.text_wrapper_2}>
                    {assessmentResults?.currentResult?.assessment_result}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "16px",
                      marginLeft: "6px",
                    }}
                  >
                    {"("}
                    {
                      profileResults[
                        assessmentResults?.currentResult?.assessment_result
                      ]?.desc
                    }
                    {")"}
                  </Typography>
                </Box>
                <Typography
                  sx={(theme) => ({
                    alignSelf: "stretch",
                    color:
                      assessmentResults?.currentResult?.assessment_result ===
                      "Agresif"
                        ? "#fff"
                        : "#333",
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "16px",
                      lineHeight: "24px",
                    },
                  })}
                >
                  {
                    profileResults[
                      assessmentResults?.currentResult?.assessment_result
                    ]?.result
                  }
                </Typography>
                <Box
                  sx={(theme) => ({
                    alignSelf: "stretch",
                    color: "#ed1b2e",
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "22px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "16px",
                      lineHeight: "24px",
                    },
                  })}
                >
                  <ul className={styles.prudential}>
                    <li
                      style={{ cursor: "pointer", width: "max-content" }}
                      onClick={() =>
                        window.open(
                          "https://www.prudential.co.id/id/investment-linked/monthly-report/",
                          "_blank"
                        )
                      }
                    >
                      Prudential Indonesia Investment Report
                    </li>
                    <li
                      style={{ cursor: "pointer", width: "max-content" }}
                      onClick={() =>
                        window.open(
                          "https://www.prudentialsyariah.co.id/id/investment-linked/monthly-report/",
                          "_blank"
                        )
                      }
                    >
                      Prudential Syariah Investment Report
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </div>
          <Box
            sx={(theme) => ({
              backgroundColor: "#d8d8d8",
              height: "1px",
              marginTop: "24px",
              marginBottom: "24px",
              width: "320px",
              [theme.breakpoints.up("md")]: {
                width: "60vw",
              },
            })}
          />
          {assessmentResults?.previousResult?.assessment_result && (
            <div className={styles.div_4}>
              <Typography
                sx={(theme) => ({
                  alignSelf: "stretch",
                  color: "#000",
                  fontFamily: "Helvetica",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "24px",
                  [theme.breakpoints.up("md")]: {
                    fontSize: "20px",
                    lineHeight: "28px",
                  },
                })}
              >
                Profil risiko Anda sebelumnya:
              </Typography>
              <div className={styles.div_5}>
                <div className={styles.img_wrapper}>
                  <img
                    className={styles.img}
                    alt="Group"
                    src={
                      profileResults[
                        assessmentResults?.previousResult?.assessment_result
                      ]?.icon
                    }
                  />
                </div>
                <div className={styles.div_6}>
                  <div className={styles.text_wrapper_4}>
                    {assessmentResults?.previousResult?.assessment_result}
                  </div>
                  <Typography
                    sx={(theme) => ({
                      alignSelf: "stretch",
                      color: "#ed1b2e",
                      fontFamily: "Helvetica",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "18px",
                      cursor: "pointer",
                      [theme.breakpoints.up("md")]: {
                        fontSize: "16px",
                        lineHeight: "24px",
                      },
                    })}
                    onClick={handleOpen}
                  >
                    Lihat Detail
                  </Typography>
                </div>
              </div>
            </div>
          )}

          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "320px",
              gap: "16px",
              [theme.breakpoints.up("md")]: {
                width: "50vw",
              },
            })}
          >
            <div className={styles.div_9}>
              <input
                type="checkbox"
                value={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                className={styles.icon_checkbox_instance}
              />
              <Typography
                sx={(theme) => ({
                  marginBottom: "24px",
                  color: "#000",
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  marginTop: "-1px",
                  [theme.breakpoints.up("md")]: {
                    fontSize: "14px",
                    lineHeight: "22px",
                  },
                })}
              >
                SAYA telah mengisi Kuesioner Profil Risiko ini dengan jujur,
                benar dan lengkap, serta telah membaca dan memahami rekomendasi
                hasil profil risiko SAYA yang terbit sesuai dengan jawaban SAYA
                atas pertanyaan yang disampaikan dalam Kuesioner Profil Risiko
                ini
              </Typography>
            </div>
            <Button
              disabled={!isChecked}
              sx={(theme) => ({
                fontStyle: "normal",
                fontFamily: "Open Sans-Bold, Helvetica",
                textTransform: "none",
                marginTop: -1,
                width: "100%",
                gap: "10px",
                borderRadius: "50px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: isChecked ? "#ed1a2d" : "#D8D8D8",
                padding: "12px 24px",
                borderColor: "#ffffff",
                [theme.breakpoints.up("md")]: {
                  width: "140px",
                  "&:hover": {
                    backgroundColor: "#ed1a2d",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                  },
                },
                "&:hover": {
                  backgroundColor: "#ed1a2d",
                  boxShadow: "0 12px 8px -2px #D3d3d3",
                },
              })}
              onClick={handleNext}
            >
              <Typography
                sx={{
                  color: isChecked ? "#ffffff" : "#808080",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  fontSize: "16px",
                  fontWeight: 700,
                  letterSpacing: 0,
                  lineHeight: "normal",
                  marginTop: "-1px",
                  position: "relative",
                  width: "fit-content",
                }}
              >
                Lanjut
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          marginTop: "24px",
        })}
      >
        <div className={styles.div_10}>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 16px",
              paddingBottom: "0px",
              backgroundColor: "#e5eaef",
              [theme.breakpoints.up("md")]: {
                width: "85vw",
                padding: "12px 50px",
                paddingBottom: "0px",
              },
            })}
          >
            <p className={styles.kinerja_subdana}>
              <span className={styles.span}>
                Kinerja Subdana mengacu pada tautan berikut &nbsp;
              </span>
              <a
                href="https://www.prudential.co.id/id/investment-linked/monthly-report/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className={styles.text_wrapper_6}>Konvensional</span>
              </a>
              <span className={styles.text_wrapper_7}>&nbsp;</span>
              <span className={styles.span}>dan</span>
              <span className={styles.text_wrapper_7}>&nbsp;</span>
              <a
                href="https://www.prudentialsyariah.co.id/id/investment-linked/monthly-report/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className={styles.text_wrapper_6}>Syariah</span>
              </a>
            </p>
          </Box>
        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={(theme) => ({
            backgroundColor: "#F7F7F7",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "75vh",
            transform: "translate(-50%, -50%)",
            borderColor: "white",
            overflow: "scroll",
            [theme.breakpoints.down("md")]: {
              position: "fixed",
              height: "75vh",
              bottom: 0,
              top: "62.5%",
              borderRadius: "16px 16px 0px 0px",
            },
          })}
        >
          <Box
            sx={{
              borderRadius: "8px 8px 0px 0px",
              backgroundColor: "#ffffff",
              position: "sticky",
              top: 0,
              margin: 0,
              padding: "24px 24px 24px 24px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <Typography
              sx={{
                fontFamily: "Helvetica",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              Profile risiko Anda sebelumnya
            </Typography>
            <Box
              sx={{
                cursor: "pointer",
                alignSelf: "center",
                height: "24px",
              }}
              onClick={handleClose}
            >
              <Close />
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "flex-start",
              display: "inline-flex",
              flex: "0 0 auto",
              flexDirection: "column",
              paddingLeft: "24px",
              paddingRight: "64px",
              paddingTop: "40px",
            }}
          >
            <div className={styles.group_wrapper}>
              <img
                width={116}
                height={116}
                className={styles.group}
                alt="Group"
                src={
                  profileResults[
                    assessmentResults?.previousResult?.assessment_result
                  ]?.icon
                }
              />
            </div>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "24px",
                width: "320px",
                [theme.breakpoints.up("md")]: {
                  width: "60vw",
                },
              })}
            >
              <div className={styles.div_3}>
                <Typography
                  sx={{
                    fontFamily: "Helvetica",
                    color: "#000",
                    fontSize: "24x",
                    fontWeight: 700,
                    lineHeight: "32px",
                    marginTop: "16px",
                    marginBottom: "12px",
                  }}
                >
                  {assessmentResults?.previousResult?.assessment_result}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    alignSelf: "stretch",
                    color: "#333",
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "16px",
                      lineHeight: "24px",
                    },
                  })}
                >
                  {
                    profileResults[
                      assessmentResults?.previousResult?.assessment_result
                    ]?.result
                  }
                </Typography>
                <Box
                  sx={(theme) => ({
                    alignSelf: "stretch",
                    color: "#ed1b2e",
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    fontWeight: "Bold",
                    lineHeight: "22px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "16px",
                      lineHeight: "24px",
                    },
                  })}
                >
                  <ul className={styles.prudential}>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://www.prudential.co.id/id/investment-linked/monthly-report/",
                          "_blank"
                        )
                      }
                    >
                      Prudential Indonesia Investment Report
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://www.prudentialsyariah.co.id/id/investment-linked/monthly-report/",
                          "_blank"
                        )
                      }
                    >
                      Prudential Syariah Investment Report
                    </li>
                  </ul>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Result;

export const Konservatif = (
  <>
    Nasabah dengan profil risiko konservatif hanya bersedia untuk menerima
    risiko investasi yang paling rendah (risiko fluktuasi hasil investasi
    rendah), menginginkan investasi yang relatif aman dan tingkat imbal hasil
    yang cenderung stabil. <br />
    <br />
    Dalam kondisi pasar yang kurang baik, lebih mementingkan untuk menjaga aset
    dan kas yang dimiliki dan menghindari penurunan nilai investasi awal.
    <br />
    <br />
    Informasi lebih lengkap terkait strategi Dana Investasi, kinerja historis
    investasi, Risiko Dana Investasi, dan kinerja acuan dapat dilihat pada fund
    fact sheet berikut:
  </>
);

export const Moderat = (
  <>
    Nasabah dengan profil risiko moderat bersedia untuk menerima risiko sedang
    dan siap dengan tingkat Imbal hasil serta risiko fluktuasi investasi sedang.
    <br />
    <br />
    Pada umumnya, imbal hasil yang diharapkan diatas rata-rata lebih besar dari
    imbal hasil deposito dan memiliki rencana berinvestasi dalam jangka waktu
    menengah (misalnya antara 5-10 tahun). Instrumen investasi yang bisa dipilih
    oleh nasabah dengan profil risiko ini antara lain obligasi dan/atau
    instrumen investasi campuran antara obligasi dan saham.
    <br />
    <br />
    Informasi lebih lengkap terkait strategi Dana Investasi, kinerja historis
    investasi, risiko Dana Investasi, dan kinerja acuan dapat dilihat pada fund
    fact sheet berikut:
  </>
);
export const Agresif = (
  <>
    Nasabah dengan profil risiko agresif bersedia untuk menerima risiko yang
    lebih tinggi dan siap dengan tingkat imbal hasil serta risiko fluktuasi yang
    besar termasuk apabila nilal investasinya berkurang dari nilai investasi
    awal.
    <br /> <br />
    Tipe profil risiko ini biasanya sudah berpengalaman dalam dunia investasi.
    Instrumen investasi yang bisa dipilih oleh nasabah dengan profil risiko ini
    adalah instrumen investasi berbasis saham. Jangka waktu investasi umumnya
    lebih panjang (lebih dari 10 tahun).
    <br />
    <br />
    Informasi lebih lengkap terkait strategi Dana Investasi, kinerja historis
    investasi, risiko Dana Investasi, dan kinerja acuan dapat dilihat pada fund
    fact sheet berikut:
  </>
);

import React from "react";
import styles from "./AnswerBox.module.css";
import { Box } from "@mui/material";

export const AnswerBox = ({
  answerOption = "Text Option",
  onAnswer,
  answerIcon,
}) => {
  return (
    <Box onClick={onAnswer} className={styles.frame}>
      <Box
        sx={(theme) => ({
          alignItems: "flex-start",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
          flex: "0 0 auto",
          gap: "12px",
          position: "relative",
          width: "260px",
          [theme.breakpoints.up("md")]: {
            width: "57vw",
          },
        })}
      >
        <img className={styles.mask_group} alt="Mask group" src={answerIcon} />
        <p className={styles.text_wrapper}>{answerOption}</p>
      </Box>
    </Box>
  );
};

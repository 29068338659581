import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Minus, Plus } from "../../assets/SVGIcon";
import styles from "./ResultMatching.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth, aesDecrypt } from "@pru-cnp/ui-polisku";

import mobileLogo from "../../assets/Icon/pru-icon.png";

export const ResultMatching = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const assessmentResults = JSON.parse(
    window.localStorage.getItem("assessmentResults")
  );
  const resultMatchings = JSON.parse(
    window.localStorage.getItem("resultMatchings")
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState(
    resultMatchings?.isUnMatch > 0
      ? `${resultMatchings?.isUnMatch} Subdana Investasi Belum Sesuai Dengan Profil Risiko Terbaru Anda`
      : `Subdana Investasi Sesuai Dengan Profil Risiko Terbaru Anda`
  );
  const [renderRecommended, setRenderRecommended] = useState(false);

  const handleNext = () => {
    if (renderRecommended) {
      handleOpen();
    }
    if (
      resultMatchings?.isUnMatch > 0 &&
      resultMatchings?.recommendedFunds[0]?.length > 0
    ) {
      setTitle("Rekomendasi Subdana Investasi Untuk Anda");
      setRenderRecommended(true);
    } else {
      navigate("/risk-profile/thankyou", {
        state: {
          isNeedRevise: resultMatchings?.isUnMatch > 0 ? true : false,
        },
      });
    }
  };

  return (
    <div className={styles.frame}>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "360px",
            marginTop: "3vh",
            [theme.breakpoints.up("md")]: {
              width: "90vw",
            },
          })}
        >
          <div onClick={() => navigate(-1)} className={styles.kembali}>
            Kembali
          </div>
          <Box
            sx={(theme) => ({
              backgroundColor: "#e5eaef",
              display: "hidden",
              [theme.breakpoints.up("md")]: {
                display: "flex",
                justifyContent: "flex-end",
                height: "30vh",
                width: "30vw",
                marginTop: "10vh",
              },
            })}
          />
        </Box>
        <Box
          sx={(theme) => ({
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginTop: "24px",
            [theme.breakpoints.up("md")]: {
              marginTop: "-35vh",
              width: "65vw",
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "32px",
              width: "288px",
              [theme.breakpoints.up("md")]: {
                width: "45vw",
                fontSize: "30px",
                lineHeight: "40px",
              },
            })}
          >
            {title}
          </Typography>
          <div className={styles.div_2}>
            {renderRecommended
              ? resultMatchings?.recommendedFunds?.map((item, index) => (
                  <RecommendedSubdana
                    key={index}
                    product={item}
                    productCode={item[0]?.product_code}
                  />
                ))
              : user?.session?.client_data?.policies.map((item, index) => {
                  return (
                    <Subdana
                      key={index}
                      product={item}
                      level={resultMatchings?.fundLevel}
                      productCode={item?.product_code}
                      isSpesialCase={item?.isSpesialCase}
                    />
                  );
                })}
          </div>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "360px",
            marginTop: "24px",
            [theme.breakpoints.up("md")]: {
              width: "65vw",
            },
          })}
        >
          <Button
            sx={(theme) => ({
              fontStyle: "normal",
              fontFamily: "Open Sans-Bold, Helvetica",
              textTransform: "none",
              display: "flex",
              flexDirection: "column",
              width: "140px",
              marginBottom: renderRecommended ? "30vh" : 0,
              padding: "12px 24px",
              backgroundColor: "#ed1a2d",
              borderRadius: "20px",
              color: "#ffffff",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
              "&:hover": {
                backgroundColor: "#ed1a2d",
                boxShadow: "0 12px 8px -2px #D3d3d3",
              },
            })}
            onClick={handleNext}
          >
            <div className={styles.txt_btn}>Lanjut</div>
          </Button>
          {!renderRecommended && (
            <Typography sx={{ marginBottom: "25vh", marginTop: "24px" }}>
              Catatan: Kesesuaian status antara pilihan subdana investasi dengan
              profil risiko pada tabel di atas mengikuti ketersediaan pilihan
              subdana investasi PRULink dengan risiko investasi terdekat.
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          marginTop: "20vh",
        })}
      >
        <Box
          sx={(theme) => ({
            display: "inline-flex",
            alignItems: "flex-start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              marginTop: "140px",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 16px",
              paddingBottom: "0px",
              backgroundColor: "#e5eaef",
              [theme.breakpoints.up("md")]: {
                width: "85vw",
                padding: "12px 50px",
                paddingBottom: "0px",
              },
            })}
          >
            <p className={styles.kinerja_subdana}>
              <span className={styles.span}>
                Kinerja Subdana mengacu pada tautan berikut &nbsp;
              </span>
              <a
                href="https://www.prudential.co.id/id/investment-linked/monthly-report/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className={styles.text_wrapper_6}>Konvensional</span>
              </a>
              <span className={styles.text_wrapper_7}>&nbsp;</span>
              <span className={styles.span}>dan</span>
              <span className={styles.text_wrapper_7}>&nbsp;</span>
              <a
                href="https://www.prudentialsyariah.co.id/id/investment-linked/monthly-report/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className={styles.text_wrapper_6}>Syariah</span>
              </a>
            </p>
          </Box>
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            backgroundColor: "#F7F7F7",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "fit-content",
            height: "fit-content",
            borderColor: "white",
          })}
        >
          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              position: "sticky",
              top: 0,
              margin: 0,
              padding: "24px 64px 24px 48px",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
                fontFamily: "Helvetica",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Apakah Anda ingin mengubah subdana investasi Anda?
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <Button
                sx={{
                  fontStyle: "normal",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  textTransform: "none",
                  display: "flex",
                  flexDirection: "column",
                  width: "140px",
                  marginTop: "24px",
                  color: "#ed1a2d",
                  borderColor: "#ed1a2d",
                  borderRadius: "20px",
                  borderStyle: "solid",
                  border: 1,
                  marginRight: "10px",
                  backgroundColor: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                  },
                }}
                onClick={() => {
                  navigate("/risk-profile/consent", {
                    state: {
                      isNeedRevise: false,
                      isWantRevise: false,
                    },
                  });
                }}
              >
                <div>Tidak</div>
              </Button>
              <Button
                sx={{
                  fontStyle: "normal",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  textTransform: "none",
                  display: "flex",
                  flexDirection: "column",
                  width: "140px",
                  marginTop: "24px",
                  backgroundColor: "#ed1a2d",
                  borderRadius: "20px",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#ed1a2d",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                  },
                }}
                onClick={() =>
                  navigate("/risk-profile/consent", {
                    state: {
                      isNeedRevise:
                        resultMatchings?.isUnMatch > 0 ? true : false,
                      isWantRevise: true,
                    },
                  })
                }
              >
                <div className={styles.txt_btn}>Ya</div>
              </Button>
            </Box>
            <Typography
              sx={{
                color: "#808080",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                fontFamily: "Helvetica",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              atau
            </Typography>
            <Typography
              sx={{
                color: "#ed1b2e",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                fontFamily: "Helvetica",
                gap: "10px",
                padding: "14px 24px",
                cursor: "pointer",
              }}
              variant="h6"
              component="h2"
              onClick={() => {
                localStorage.removeItem("assessmentResults");
                localStorage.removeItem("resultMatchings");
                navigate("/risk-profile/questionnaire");
              }}
            >
              Cek ulang profil risiko
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export const Subdana = ({ product, level, productCode, isSpesialCase }) => {
  if (product?.fund_list.length === 0) return null;
  const [openCard, setOpenCard] = React.useState(false);
  const handleOpenCard = () => setOpenCard(!openCard);

  const productImage = () => {
    try {
      const image = require(`../../assets/images/Products/${productCode}.png`);
      return image;
    } catch (error) {
      return null;
    }
  };

  const renderSesuai = (item) => {
    if (isSpesialCase && (item?.risk === "Rendah" || item?.risk === "Sedang")) {
      return "Sesuai";
    }

    if (level === "L" && item?.risk === level) {
      return "Sesuai";
    }

    if (level === "M" && item?.risk !== "Tinggi") {
      return "Sesuai";
    }

    if (level === "H") {
      return "Sesuai";
    }

    return "Belum Sesuai";
  };

  const renderColorSesuai = (item) => {
    if (isSpesialCase && (item?.risk === "Rendah" || item?.risk === "Sedang")) {
      return "#26c022";
    }

    if (level === "L" && item?.risk === level) {
      return "#26c022";
    }

    if (level === "M" && item?.risk !== "Tinggi") {
      return "#26c022";
    }

    if (level === "H") {
      return "#26c022";
    }

    return "#F04E1B";
  };

  const renderFunds = product?.fund_list?.map((item, index) => {
    return (
      <Box
        key={index}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "320px",
          [theme.breakpoints.up("md")]: {
            width: "65vw",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "220%",
            [theme.breakpoints.up("md")]: {
              width: "100%",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              flex: "50%",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Helvetica",
            })}
          >
            {item?.fund_name ? item?.fund_name : "Fund Name"}
          </Box>
          <Typography
            sx={(theme) => ({
              flex: "15%",
              color: "#000",
              fontFamily: "Helvetica",
              fontSize: "14px",
              fontWeight: 400,
              marginTop: "16px",
              marginBottom: "16px",
              [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                lineHeight: "24px",
              },
            })}
          >
            {item?.risk}
          </Typography>
          <Typography
            sx={(theme) => ({
              flex: "20%",
              color: "#000",
              fontFamily: "Helvetica",
              fontSize: "14px",
              fontWeight: 400,
              [theme.breakpoints.up("md")]: {
                fontSize: "16px",
                lineHeight: "24px",
              },
            })}
          >
            {item?.fund_price
              ? formatPrice(Math.round(item?.fund_price), "Rp", item?.currency)
              : 0}
          </Typography>
          <Typography
            sx={(theme) => ({
              color: renderColorSesuai(item),
              textAlign: "left",
              paddingBottom: "10px",
              paddingTop: "10px",
              gap: "10px",
              flex: "15%",
              fontFamily: "Helvetica",
              fontSize: "16px",
            })}
          >
            {renderSesuai(item)}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: "#eeeeee",
            height: "1px",
            width: "220%",
            marginTop: "5px",
            marginBottom: "5px",
            [theme.breakpoints.up("md")]: {
              width: "100%",
            },
          })}
        />
      </Box>
    );
  });

  return (
    <Box
      sx={(theme) => ({
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 26px 30px 0px rgba(0, 0, 0, 0.03)",
        padding: "16px",
        width: "320px",
        [theme.breakpoints.up("md")]: {
          width: "65vw",
          padding: "24px",
        },
      })}
    >
      <div className={styles.div_3}>
        <img
          className={styles.rectangle}
          alt="Rectangle"
          src={productImage()}
        />
        <div className={styles.div_4}>
          <div className={styles.text_wrapper_2}>
            {product?.marketing_name ? product?.marketing_name : "Nama Produk"}
          </div>
          <div className={styles.text_wrapper_3}>
            {product?.policy_no ? product?.policy_no : "Nomor Polis"}
          </div>
        </div>
        {product?.fund_list.length > 0 && (
          <div onClick={handleOpenCard} className={styles.icon_minus_wrapper}>
            {openCard ? (
              <Minus className={styles.icon_minus} />
            ) : (
              <Plus className={styles.icon_minus} />
            )}
          </div>
        )}
      </div>
      {openCard && (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "auto",
            whiteSpace: "nowrap",
            [theme.breakpoints.up("md")]: {
              width: "65vw",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              width: "320px",
              [theme.breakpoints.up("md")]: {
                width: "65vw",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "220%",
                [theme.breakpoints.up("md")]: {
                  width: "100%",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  flex: "50%",
                })}
              />
              <Typography
                sx={(theme) => ({
                  flex: "15%",
                  color: "#808080",
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  fontWeight: 400,
                  [theme.breakpoints.up("md")]: {
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                })}
              >
                Tingkat Risiko
              </Typography>
              <Typography
                sx={(theme) => ({
                  flex: "20%",
                  color: "#808080",
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  fontWeight: 400,
                  [theme.breakpoints.up("md")]: {
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                })}
              >
                Nilai Akun
              </Typography>
              <Typography
                sx={(theme) => ({
                  flex: "15%",
                  color: "#808080",
                  fontFamily: "Helvetica",
                  fontSize: "16px",
                  fontWeight: 400,
                })}
              >
                Status
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                backgroundColor: "#eeeeee",
                height: "1px",
                width: "220%",
                marginTop: "5px",
                marginBottom: "5px",
                [theme.breakpoints.up("md")]: {
                  width: "100%",
                },
              })}
            />
          </Box>
          {product?.fund_list.length > 0 ? renderFunds : null}
        </Box>
      )}
    </Box>
  );
};

export const RecommendedSubdana = ({ product, productCode }) => {
  const [openCard, setOpenCard] = React.useState(false);
  const handleOpenCard = () => setOpenCard(!openCard);

  const productImage = () => {
    try {
      const image = require(`../../assets/images/Products/${productCode}.png`);
      return image;
    } catch (error) {
      return null;
    }
  };

  const renderFund = product[0]?.funds?.map((item, index) => (
    <Box
      key={index}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        width: "320px",
        [theme.breakpoints.up("md")]: {
          width: "65vw",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          [theme.breakpoints.up("md")]: {
            width: "65vw",
            paddingLeft: 0,
          },
        })}
      >
        <Typography
          sx={(theme) => ({
            color: "#000",
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: 0,
            lineHeight: "22px",
            fontFamily: "Helvetica",
            [theme.breakpoints.up("md")]: {
              fontSize: "16px",
              lineHeight: "24px",
            },
          })}
        >
          {item?.fund_name ? item?.fund_name : "Fund Name"}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: "#808080",
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "10px",
            marginTop: "6px",
            whiteSpace: "nowrap",
            fontFamily: "Helvetica",
            lineHeight: "22px",
            [theme.breakpoints.up("md")]: {
              fontSize: "16px",
              lineHeight: "24px",
            },
          })}
        >
          Tingkat Risiko: {item?.risk ? item?.risk : ""}
        </Typography>
      </Box>
      <div className={styles.rectangle_2} />
    </Box>
  ));

  return (
    <Box
      sx={(theme) => ({
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 26px 30px 0px rgba(0, 0, 0, 0.03)",
        padding: "16px",
        width: "320px",
        [theme.breakpoints.up("md")]: {
          width: "65vw",
          padding: "24px",
        },
      })}
    >
      <div className={styles.div_3}>
        <img
          className={styles.rectangle}
          alt="Rectangle"
          src={productImage()}
        />
        <div className={styles.div_4}>
          <div className={styles.text_wrapper_2}>
            {product[0]?.marketing_name}
          </div>
          <div className={styles.text_wrapper_3}>
            {product[0]?.policy_number}
          </div>
        </div>
        <div onClick={handleOpenCard} className={styles.icon_minus_wrapper}>
          {openCard ? (
            <Minus className={styles.icon_minus} />
          ) : (
            <Plus className={styles.icon_minus} />
          )}
        </div>
      </div>
      {openCard && (
        <>
          <div className={styles.label}>
            <p className={styles.anda_memiliki}>
              SUBDANA YANG DIREKOMENDASIKAN:
            </p>
          </div>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "320px",
              [theme.breakpoints.up("md")]: {
                width: "65vw",
              },
            })}
          >
            {renderFund}
          </Box>
        </>
      )}
    </Box>
  );
};

function formatPrice(angka, prefix, currency) {
  if (currency === "IDR") {
    let number_string = angka
        .toString()
        .replace(/[^,\d]/g, "")
        .toString(),
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return prefix == undefined ? rupiah : rupiah ? "Rp" + rupiah : "";
  }
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return USDollar.format(angka);
}

export default ResultMatching;

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./ThankYou.module.css";
import Answer1 from "../../assets/SVGIcon/answer1.svg";
import ArrowNext from "../../assets/SVGIcon/arrow-next.svg";
import ResultIcon from "../../assets/SVGIcon/result-icon.svg";
import { useAuth, aesDecrypt } from "@pru-cnp/ui-polisku";
import { useAxios } from "@pru-cnp/ui-polisku";

import mobileLogo from "../../assets/Icon/pru-icon.png";

export const ThankYou = () => {
  const { logout } = useAuth();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const [results, setResults] = useState(
    JSON.parse(window.localStorage.getItem("results")) || null
  );

  useEffect(() => {
    if (results!=null) {
      useAxios({
        method: "post",
        url: `/rpq/assessment`,
        body: {
          result: results,
        },
      }).then((response) => {
        if (response?.status == 200) {
          localStorage.removeItem("results");
          setResults(null);
        } else if (response?.status == 403 || response?.status === 401) {
          logout();
        }
      });
    }
  }, []);
  
  return (
    <Box
      sx={(theme) => ({
        background: "#f7f7f7",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "35vh",
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        })}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: "#e5eaef",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            padding: "19px",
            gap: "24px",
            position: "relative",
            height: "500px",
            marginRight: "40px",
            marginLeft: "40px",
            width: "80vw",
            [theme.breakpoints.down("md")]: {
              backgroundColor: "#f7f7f7",
              marginRight: "0px",
              marginLeft: "0px",
            },
          })}
          className={styles.thankyou_container}
        >
          <div className={styles.group_wrapper}>
            <img className={styles.group} alt="Group" src={ResultIcon} />
          </div>
          <div className={styles.thankyou_frame_wrapper}>
            <div className={styles.thankyou_div}>
              <div className={styles.thankyou_text_wrapper}>Terima Kasih</div>
              <p className={styles.thankyou_p}>
                {`telah melakukan pengecekan Profil Risiko Anda. Informasi ini akan dikirim ke 𝘦𝘮𝘢𝘪𝘭 Anda yang terdaftar pada sistem Prudential. Untuk mengubah subdana investasi Anda, silakan hubungi Customer Line 1500085 atau Tenaga Pemasar.`}
              </p>
            </div>
          </div>
        </Box>
        <div
          onClick={() =>
            window.open("https://www.research.net/r/YWWJXQP", "_blank")
          }
          className={styles.frame}
        >
          <div className={styles.div}>
            <img className={styles.mask_group} alt="Mask group" src={Answer1} />
            <Typography
              sx={(theme) => ({
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                marginRight: "50px",
                fontFamily: "Helvetica",
                [theme.breakpoints.down("md")]: {
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "18px",
                  marginRight: "10px",
                },
              })}
            >
              Bagaimana pengalaman Anda setelah mengecek <br />
              profil risiko terbaru Anda?
            </Typography>
          </div>
          <div className={styles.box}>
            <div className={styles.ARROW}>
              <img className={styles.SHAPE} alt="Shape" src={ArrowNext} />
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ThankYou;

import React, { useEffect, useState } from "react";
import { AnswerBox } from "../../components/AnswerBox";
import styles from "./Questionnaire.module.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAxios } from "@pru-cnp/ui-polisku";
import { useAuth, aesDecrypt } from "@pru-cnp/ui-polisku";

import mobileLogo from "../../assets/Icon/pru-icon.png";

import q1 from "../../assets/SVGIcon/q1.svg";
import q2 from "../../assets/SVGIcon/q2.svg";
import q3 from "../../assets/SVGIcon/q3.svg";
import q4 from "../../assets/SVGIcon/q4.svg";
import q5 from "../../assets/SVGIcon/q5.svg";

import q1a1 from "../../assets/SVGIcon/q1a1.svg";
import q1a2 from "../../assets/SVGIcon/q1a2.svg";
import q1a3 from "../../assets/SVGIcon/q1a3.svg";

import q2a1 from "../../assets/SVGIcon/q2a1.svg";
import q2a2 from "../../assets/SVGIcon/q2a2.svg";
import q2a3 from "../../assets/SVGIcon/q2a3.svg";

import q3a1 from "../../assets/SVGIcon/q3a1.svg";
import q3a2 from "../../assets/SVGIcon/q3a2.svg";
import q3a3 from "../../assets/SVGIcon/q3a3.svg";

import q4a1 from "../../assets/SVGIcon/q4a1.svg";
import q4a2 from "../../assets/SVGIcon/q4a2.svg";
import q4a3 from "../../assets/SVGIcon/q4a3.svg";

import q5a1 from "../../assets/SVGIcon/q5a1.svg";
import q5a2 from "../../assets/SVGIcon/q5a2.svg";
import q5a3 from "../../assets/SVGIcon/q5a3.svg";

const questionIcon = [
  { question: q1, a: q1a1, b: q1a2, c: q1a3 },
  { question: q2, a: q2a1, b: q2a2, c: q2a3 },
  { question: q3, a: q3a1, b: q3a2, c: q3a3 },
  { question: q4, a: q4a1, b: q4a2, c: q4a3 },
  { question: q5, a: q5a1, b: q5a2, c: q5a3 },
];

const questionDataBckp = {
  question_1: {
    question:
      "Sejauh mana pengetahuan Anda terkait Investasi (termasuk risiko investasi)?",
    answer: [
      {
        description: "Terbatas, pada tabungan atau deposito berjangka",
        weight: 0,
      },
      {
        description:
          "Baik, seperti reksa dana, obligasi serta risiko penurunan nilai investasi karena pergerakan pasar",
        weight: 1,
      },
      {
        description:
          "Sangat baik, seperti ekuitas, foreign exchange (forex) / valuta asing (valas) serta risikonya",
        weight: 2,
      },
    ],
  },
  question_2: {
    question: "Apa tujuan investasi Anda?",
    answer: [
      {
        description:
          "Lebih fokus untuk menghasilkan pengembalian investasi yang stabil, dibandingkan pada pertumbuhan investasi (potensi tingkat pengembalian yang lebih rendah dan tingkat volatilitas lebih rendah)",
        weight: 0,
      },
      {
        description:
          "Bersedia menerima dampak sedang pergerakan pasar dan mengharapkan sejumlah pertumbuhan investasi (potensi tingkat pengembalian sedang dan tingkat pergerakan pasar sedang)",
        weight: 1,
      },
      {
        description:
          "Fokus pada pertumbuhan investasi, kurang tertarik untuk menghasilkan penghasilan investasi yang stabil dan bersedia menerima dampak pergerakan pasar (potensi tingkat pengembalian dan tingkat pergerakan pasar yang tinggi)",
        weight: 2,
      },
    ],
  },
  question_3: {
    question:
      "Dalam hal kinerja pasar kurang baik seperti pada masa krisis moneter, pernyataan mana yang paling menggambarkan ukuran risiko yang dapat Anda terima dalam hal berinvestasi? Bersedia menerima penurunan sebesar ... dari investasi awal",
    answer: [
      {
        description: "<5%",
        weight: 0,
      },
      {
        description: "5-15%",
        weight: 1,
      },
      {
        description: ">15%",
        weight: 2,
      },
    ],
  },
  question_4: {
    question:
      "Dalam pengalaman Anda berinvestasi di 3 (tiga) tahun terakhir, apa mayoritas proporsi investasi anda (>60%)",
    answer: [
      {
        description:
          "Tidak pernah berinvestasi atau hanya berinvestasi dalam bentuk Tabungan atau Deposito berjangka",
        weight: 0,
      },
      {
        description: "Reksa dana atau Obligasi",
        weight: 1,
      },
      {
        description: "Saham, atau Valuta Asing (Valas)",
        weight: 2,
      },
    ],
  },
  question_5: {
    question:
      "Berapa lama jangka waktu investasi anda ditujukan untuk membiayai kebutuhan?",
    answer: [
      {
        description: "<5 tahun",
        weight: 0,
      },
      {
        description: "5-10 tahun",
        weight: 1,
      },
      {
        description: ">10 tahun",
        weight: 2,
      },
    ],
  },
};

export const Questionnaire = () => {
  const { logout } = useAuth();
  const deviceID = window.localStorage.getItem("deviceID");
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState({});
  const [currentNo, setCurrentNo] = useState(
    JSON.parse(window.localStorage.getItem("currentNo")) || 1
  );
  const [currentQuestion, setCurrentQuestion] = useState(
    questionDataBckp[`question_${currentNo}`]
  );
  const [results, setResults] = useState(
    JSON.parse(window.localStorage.getItem("results")) || []
  );
  const handleBack = () => {
    if (currentNo - 1 < 1) navigate("/");
    setCurrentNo(currentNo - 1);
  };

  const handleOnAnswer = (weight) => {
    let isExist = false;
    const newResults = [...results];
    newResults.map((result) => {
      if (result.id === currentNo.toString()) {
        isExist = true;
        return (result.weight = weight);
      }
    });

    if (!isExist) newResults.push({ id: currentNo.toString(), weight });

    setResults(newResults);
    setCurrentNo(currentNo + 1);
    localStorage.setItem("results", JSON.stringify(results));
  };

  useEffect(() => {
    localStorage.setItem("results", JSON.stringify(results));
  }, [results]);

  useEffect(() => {
    useAxios({
      method: "get",
      url: `/risk-profile/questionnaire`,
    }).then((response) => {
      if (response?.status == 200) {
        setQuestionData(response?.data?.data);
      } else if (response?.status == 403 || response?.status === 401) {
        logout();
      }
    });
    if (questionData.length === 0) setQuestionData(questionDataBckp);
  }, []);

  useEffect(() => {
    if (questionData.length !== 0) {
      setCurrentQuestion(questionData[`question_${currentNo}`]);
    }
  }, [questionData]);

  useEffect(() => {
    if (currentNo > 5) {
      useAxios({
        method: "post",
        url: "/risk-profile/save-assessment",
        body: {
          result: results,
        },
      }).then((response) => {
        if (response?.status == 200) {
          if (response?.data?.resp_code == 0) {
            localStorage.removeItem("currentNo");
            localStorage.removeItem("results");
            const currentResult = response.data?.assessment_result;
            const previousResult = response.data?.previous_result;

            if (currentResult?.recomended_fund_level === "L")
              currentResult.assessment_result = "Konservatif";
            if (currentResult?.recomended_fund_level === "M")
              currentResult.assessment_result = "Moderat";
            if (currentResult?.recomended_fund_level === "H")
              currentResult.assessment_result = "Agresif";

            if (previousResult?.recomended_fund_level === "L")
              previousResult.assessment_result = "Konservatif";
            if (previousResult?.recomended_fund_level === "M")
              previousResult.assessment_result = "Moderat";
            if (previousResult?.recomended_fund_level === "H")
              previousResult.assessment_result = "Agresif";

            const assessmentResults = {
              currentResult: response.data?.assessment_result,
              previousResult: response.data?.previous_result,
            };
            localStorage.setItem(
              "assessmentResults",
              JSON.stringify(assessmentResults)
            );
            navigate("/risk-profile/result");
            setCurrentNo(1);
          } else {
            alert(response?.data?.resp_desc || "Terjadi kesalahan.");
            setCurrentNo(5);
          }
        } else {
          if (response?.status === 403 || response?.status === 401) {
            logout();
          }
          alert(response?.data?.resp_desc || "Terjadi kesalahan.");
          setCurrentNo(5);
        }
      });
    } else {
      if (questionData.length !== 0)
        setCurrentQuestion(questionData[`question_${currentNo}`]);
      else setCurrentQuestion(questionDataBckp[`question_${currentNo}`]);
    }
    localStorage.setItem("currentNo", JSON.stringify(currentNo));
  }, [currentNo]);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "#f7f7f7",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        paddingBottom: "30vh",
        height: "100%",
        [theme.breakpoints.up("lg")]: {
          height: "100vh",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          marginTop: "24px",
          width: "300px",
          [theme.breakpoints.up("md")]: {
            width: "80vw",
          },
        })}
      >
        <div className={styles.nav}>
          <div className={styles.frame_5}>
            <Typography
              sx={{
                display: currentNo > 5 ? "none" : "block",
                color: "#000000",
                fontFamily: "Noto Sans-Bold, Helvetica",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleBack}
            >
              Kembali
            </Typography>
          </div>
        </div>
      </Box>
      {questionData.length !== 0 && currentNo <= 5 ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            alignSelf: "center",
            width: "300px",
            [theme.breakpoints.up("md")]: {
              width: "60vw",
            },
          })}
        >
          <div className={styles.frame_2}>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "8px",
                width: "300px",
                [theme.breakpoints.up("md")]: {
                  width: "60vw",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "300px",
                  [theme.breakpoints.up("md")]: {
                    width: "60vw",
                  },
                })}
              >
                <Typography
                  sx={(theme) => ({
                    color: "#808080",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    [theme.breakpoints.up("md")]: {
                      fontSize: "14px",
                      lineHeight: "22px",
                    },
                  })}
                >
                  Pertanyaan
                </Typography>
                <Typography
                  sx={(theme) => ({
                    color: "#333333",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    textAlign: "right",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "14px",
                      lineHeight: "22px",
                    },
                  })}
                >
                  <span className={styles.current_no}>{currentNo}</span> dari 5
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: currentNo === 2 ? "center" : "flex-start",
                }}
              >
                <img src={questionIcon[currentNo - 1].question} />
                <Typography
                  sx={(theme) => ({
                    color: "#000000",
                    fontFamily: "Open Sans-Regular, Helvetica",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    width: "235px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "20px",
                      lineHeight: "28px",
                      width: "55vw",
                    },
                  })}
                >
                  {currentQuestion?.question}
                </Typography>
              </Box>
              <Typography
                sx={(theme) => ({
                  color: "#808080",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  margin: "10px 0 10px",
                  [theme.breakpoints.up("md")]: {
                    fontSize: "14px",
                    lineHeight: "22px",
                  },
                })}
              >
                Pilih Jawaban
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "8px",
                width: "300px",
                [theme.breakpoints.up("md")]: {
                  width: "60vw",
                },
              })}
            >
              <AnswerBox
                onAnswer={() =>
                  handleOnAnswer(currentQuestion?.answer[0]?.weight)
                }
                answerOption={currentQuestion?.answer[0]?.description}
                answerIcon={questionIcon[currentNo - 1].a}
              />
              <AnswerBox
                onAnswer={() =>
                  handleOnAnswer(currentQuestion?.answer[1]?.weight)
                }
                answerOption={currentQuestion?.answer[1]?.description}
                answerIcon={questionIcon[currentNo - 1].b}
              />
              <AnswerBox
                onAnswer={() =>
                  handleOnAnswer(currentQuestion?.answer[2]?.weight)
                }
                answerOption={currentQuestion?.answer[2]?.description}
                answerIcon={questionIcon[currentNo - 1].c}
              />
            </Box>
          </div>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignSelf: "center", height: "100vh" }}>
          <CircularProgress
            sx={{ display: "flex", alignSelf: "center", height: "100vh" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Questionnaire;

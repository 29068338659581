import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import ThankYou from "./pages/thank/ThankYou";
import Result from "./pages/result/Result";
import {
  NavbarPolisku,
  FooterPolisku,
  AuthProvider,
  useAuth,
  aesDecrypt,
} from "@pru-cnp/ui-polisku";
import Questionnaire from "./pages/questionnaire/Questionnaire";
import Consent from "./pages/consent/Consent";
import ResultMatching from "./pages/result/ResultMatching";

function RequireAuth({ children }) {
  const { user } = useAuth();
  const location = useLocation();

  return user ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

function RequireAssessment({ children }) {
  const assessmentResults = JSON.parse(
    window.localStorage.getItem("assessmentResults")
  );
  const location = useLocation();

  return assessmentResults ? (
    children
  ) : (
    <Navigate
      to="/risk-profile/questionnaire"
      replace
      state={{ path: location.pathname }}
    />
  );
}

function RequireMatching({ children }) {
  const resultMatchings = JSON.parse(
    window.localStorage.getItem("resultMatchings")
  );
  const location = useLocation();

  return resultMatchings ? (
    children
  ) : (
    <Navigate
      to="/risk-profile/result"
      replace
      state={{ path: location.pathname }}
    />
  );
}

function NotFound() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1>404 Halaman Tidak Ditemukan</h1>
    </div>
  );
}

export default function Root(props) {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));

  return (
    <BrowserRouter>
      <AuthProvider userData={user}>
        <NavbarPolisku />
        <Routes>
          <Route
            path="/risk-profile/questionnaire"
            element={
              <RequireAuth>
                <Questionnaire />
              </RequireAuth>
            }
          />
          <Route
            path="/risk-profile/result"
            element={
              <RequireAuth>
                <RequireAssessment>
                  <Result />
                </RequireAssessment>
              </RequireAuth>
            }
          />
          <Route
            path="/risk-profile/result-matching"
            element={
              <RequireAuth>
                <RequireAssessment>
                  <RequireMatching>
                    <ResultMatching />
                  </RequireMatching>
                </RequireAssessment>
              </RequireAuth>
            }
          />
          <Route
            path="/risk-profile/thankyou"
            element={
              <RequireAuth>
                <RequireMatching>
                  <ThankYou />
                </RequireMatching>
              </RequireAuth>
            }
          />
          <Route
            path="/risk-profile/consent"
            element={
              <RequireAuth>
                <RequireMatching>
                  <Consent />
                </RequireMatching>
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <FooterPolisku />
      </AuthProvider>
    </BrowserRouter>
  );
}

import React, { useState } from "react";
import "./ConsentForm.css";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, aesDecrypt } from "@pru-cnp/ui-polisku";

import mobileLogo from "../../assets/Icon/pru-icon.png";

export const Consent = () => {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const { state } = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isConfirm, setIsConfirm] = useState(false);
  return (
    <div className="consent-container">
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            paddingTop: "5vh",
            width: "322px",
            [theme.breakpoints.up("md")]: {
              backgroundColor: "#e5eaef",
              width: "85vw",
              height: "16vh",
              padding: "5vh",
            },
          })}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            className="div"
          >
            Kembali
          </div>
          {state?.isWantRevise ? (
            <Box
              sx={(theme) => ({
                padding: "8px",
                border: 1,
                width: "322px",
                alignSelf: "center",
                height: "16vh",
                backgroundColor: "#d8d8d8",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                [theme.breakpoints.up("md")]: {
                  width: "75vw",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  marginLeft: "40px",
                  marginTop: "-30px",
                },
              })}
            >
              <Typography
                sx={(theme) => ({
                  fontFamily: "Noto Sans-Bold, Helvetica",
                  textAlign: "left",
                  fontSize: "16px",
                  [theme.breakpoints.up("md")]: {
                    textAlign: "center",
                  },
                })}
              >
                Untuk mengubah subdana investasi Anda, silakan hubungi Customer
                Line 1500085, Tenaga Pemasar atau gunakan aplikasi PULSE
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={(theme) => ({
                fontFamily: "Noto Sans-Bold, Helvetica",
                textAlign: "left",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "32px",
                marginTop: "25px",
                [theme.breakpoints.up("md")]: {
                  textAlign: "center",
                  lineHeight: "40px",
                },
              })}
            >
              Pernyataan Pemegang Polis
            </Typography>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            width: "322px",
            height: "100%",
            paddingBottom: "30vh",
            [theme.breakpoints.up("md")]: {
              width: "80vw",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignUtems: "flex-start",
              display: "flex",
              flexDirection: "column",
              width: "322px",
              height: "100%",
              paddingBottom: "30vh",
              [theme.breakpoints.up("md")]: {
                width: "80vw",
                paddingTop: "3vh",
              },
            })}
          >
            {state?.isWantRevise && (
              <p
                className="SAYA-sebagai"
                style={{
                  fontWeight: 700,
                  fontSize: "22px",
                }}
              >
                Pernyataan Pemegang Polis
              </p>
            )}
            <p className="SAYA-sebagai">
              SAYA sebagai Pemegang Polis menyatakan bahwa:
            </p>
            <p className="SAYA-sebagai">
              Dana Investasi PRULink yang sudah maupun akan SAYA pilih
              sepenuhnya merupakan keputusan SAYA secara mandiri. Dalam memilih
              Dana Investasi PRULink, SAYA telah mempertimbangkan pengalaman
              investasi, pemahaman atas jenis instrumen dan risiko investasi,
              selera risiko (<span className="span">risk appetite</span>
              ), tingkat toleransi risiko, tujuan dan jangka waktu investasi,
              kondisi keuangan, kebutuhan, kemampuan dan profil risiko SAYA.{" "}
              {state?.isWantRevise
                ? "Selanjutnya saya akan melakukan perubahan penempatan dana investasi sesuai hasil profil risiko saya dan "
                : "SAYA tidak melakukan perubahan penempatan dana investasi sesuai risiko profil SAYA karena "}{" "}
              SAYA mengerti dan bertanggung jawab atas segala risiko yang
              ditimbulkan jika SAYA memilih Dana Investasi PRULink dengan profil
              risiko{" "}
              {!state?.isWantRevise && (
                <>
                  yang tidak sesuai (<span className="span">mismatch</span>){" "}
                </>
              )}
              berdasarkan rekomendasi hasil profil risiko SAYA sebagaimana
              disampaikan dalam Kuesioner Profil Risiko ini.
            </p>
            <div className="consent-frame-3">
              <div className="consent-frame-wrapper">
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                    width: "322px",
                    [theme.breakpoints.up("md")]: {
                      width: "80vw",
                    },
                  })}
                >
                  <div className="consent-frame-4">
                    <div className="konfirmasi">
                      <input
                        type="checkbox"
                        className="konfirmasi-rectangle"
                        value={isConfirm}
                        onChange={() => setIsConfirm(!isConfirm)}
                      />
                    </div>
                    <p className="consent-p">
                      Saya telah membaca dan setuju dengan syarat dan ketentuan
                      yang tertera di atas.
                    </p>
                  </div>
                </Box>
              </div>
              <ButtonNext
                className="frame-34042"
                isWantRevise={state?.isWantRevise}
                isConfirm={isConfirm}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export const ButtonNext = ({ isWantRevise, isConfirm }) => {
  const navigate = useNavigate();
  return (
    <Button
      disabled={!isConfirm}
      sx={(theme) => ({
        fontStyle: "normal",
        fontFamily: "Open Sans-Bold, Helvetica",
        textTransform: "none",
        marginTop: "10px",
        display: "flex",
        alignItems: "flex-start",
        alignSelf: "flex-start",
        gap: "10px",
        justifyContent: "center",
        padding: "12px 24px",
        width: "140px",
        cursor: "pointer",
        borderColor: "#f7f7f7",
        backgroundColor: isConfirm ? "#ed1a2d" : "#D8D8D8",
        borderRadius: "100px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "&:hover": {
          backgroundColor: "#ed1a2d",
          boxShadow: "0 12px 8px -2px #D3d3d3",
        },
      })}
      onClick={() =>
        navigate("/risk-profile/thankyou", {
          state: {
            isWantRevise,
          },
        })
      }
    >
      <Typography
        sx={{
          color: isConfirm ? "#ffffff" : "#808080",
          fontFamily: "Open Sans-Bold, Helvetica",
          fontWeight: "700",
          fontSize: "16px",
        }}
      >
        Lanjut
      </Typography>
    </Button>
  );
};

export default Consent;
